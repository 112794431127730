<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      active: 0,
      tabbarShow: true,
    }
  },
  watch: {
    // $route: {
    //   handler: function(newVal, oldVal){
    //     console.log(newVal, oldVal)
    //     if (oldVal.name && oldVal.path.startsWith('/')) {
    //       this.$store.commit('mutation_showMenu', true)
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    toPage(path) {
      this.$router.push({ path });
    }
  },
  mounted() {
    this.$store.commit('mutation_showMenu', false)
  }
}
</script>

<style lang="less">
@import '~@/styles/reset.less';
@font-face {
  font-family: 'iconfont';
  src: url('./assets/font/iconfont.woff2?t=1658325800829') format('woff2'),
       url('./assets/font/iconfont.woff?t=1658325800829') format('woff'),
       url('./assets/font/iconfont.ttf?t=1658325800829') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  src: url('./assets/font/Open Sans Bold.ttf');
}
@font-face {
  font-family: 'Oswald';
  src: url('./assets/font/Oswald-SemiBold.otf');
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/font/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Arial Rounded MT Bold';
  src: url('./assets/font/Arial Rounded MT Bold.ttf');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
  font-family: "Open Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}
</style>
