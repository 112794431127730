import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  firstInit: true,
  showMenu: false,
  showVideo: false,
};

const getters = {
  getFirstInit: state => state.firstInit,
  getShowMenu: state => state.showMenu,
  getShowVideo: state => state.showVideo,
};

const mutations = {
  mutation_firstInit(state, firstInit) {
    state.firstInit = firstInit;
    console.log('SET_firstInit', firstInit);
  },
  mutation_showMenu(state, showMenu) {
    state.showMenu = showMenu;
    console.log('SET_showMenu', showMenu);
  },
  mutation_showVideo(state, showVideo) {
    state.showVideo = showVideo;
    console.log('SET_showVideo', showVideo);
  },
};


const actions = {
  // action_role({ commit }, newVal) {
  //   console.log('context---', newVal);
  //   commit('mutation_role', newVal);
  // },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
})