import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from './store';
import Cookies from 'js-cookie';
import axios from 'axios';
import Videojs from 'video.js'

import 'video.js/dist/video-js.css'

import { Icon, Tabbar, TabbarItem, Form, Field, Button, NoticeBar, Notify, Toast, Dialog } from 'vant';

Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(NoticeBar);



Vue.config.productionTip = false

Vue.use(Cookies);
Vue.prototype.$video = Videojs;
Vue.prototype.$axios = axios;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$Notify = Notify;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
